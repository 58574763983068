import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
import { useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import common from "features/common/commonSlice";
import { listShowBoothItems } from "../../../../graphql/queries";

type ShowProps = {
  showID?: String;
} & Router.RouteComponentProps;

export default function Shows(props: ShowProps) {
  const dispatch = useDispatch();
  // @todo 編集画面を通すとnot asignで通過してしまう
  // console.log(Router.useParams(),props);
  const { showID } = props;
  const variables = {
    filter: {
      showID: { eq: showID },
    },
  };
  return (
    <>
      <Table
        type="showBooths"
        variables={variables}
        childList="boothitems"
        belong={{ key: "boothID", target: `boothUserInfos` }}
        enableDelete={async (rowData: any) => {
          const res = await API.graphql(
            graphqlOperation(listShowBoothItems, {
              filter: { boothID: { eq: rowData.id } },
            })
          );
          if (res.data.listShowBoothItems.items.length === 0) {
            return true;
          }
          dispatch(
            common.actions.fetchFailure({
              errors: [
                { message: "先に紐づくブースアイテムを削除してください。" },
              ],
            })
          );
          return false;
        }}
      />
    </>
  );
}
