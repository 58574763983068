import * as React from "react";
import * as Router from "@reach/router";
import Form from "components/organisims/Form";
import { teaser } from "settings/FormSettings";
import PreviewTeaser from "components/pages/cms/shows/preview/Teaser";

export default function Show() {
  const { action, ...rest } = Router.useParams();

  return (
    <>
      {action !== "preview" && <Form dataType="teaser" formParams={teaser} />}

      {action === "preview" && <PreviewTeaser {...rest} />}
    </>
  );
}
