import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { TextField, Button, Grid } from "@material-ui/core";
import { createTicket } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import * as Router from "@reach/router";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      // display: 'flex',
      // flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "16rem",
    },
  })
);

export default function Show() {
  const { showID } = Router.useParams();
  const classes = useStyles();

  const { register, handleSubmit } = useForm();
  const onSubmit = (data: any) => {
    async function excute() {
      data.showID = showID;
      // ユーザーを紐づける場合
      // data.ticketActivatedUserId = userId
      await API.graphql(graphqlOperation(createTicket, { input: data }));
      window.history.back();
    }
    excute();
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="id"
              name="id"
              inputRef={register}
              style={{ margin: 8 }}
              placeholder="idを入力してください。"
              helperText=""
              className={classes.textField}
              variant="filled"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="userID"
              name="userID"
              inputRef={register}
              style={{ margin: 8 }}
              placeholder="idを入力してください。"
              helperText=""
              className={classes.textField}
              variant="filled"
              value=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
