import * as React from "react";

type Props = {
  children: React.ReactNode;
};

export default function Viewer(props: Props) {
  const { children } = props;
  return (
    <>
      <h6>PC</h6>
      <div style={{ width: 800 }}>{children}</div>
      <h6>SP</h6>
      <div style={{ width: 378 }}>{children}</div>
    </>
  );
}
