import * as React from "react";
import BoothItem from "components/pages/front/BoothItem";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import {
  getShow,
  getShowBooth,
  listBoothUserInfos,
  getShowBoothItem,
} from "graphql/queries";
import Preview from "./Preivew";

export default function Show() {
  const { showID, boothID, id } = Router.useParams();
  const [boothItem, setBoothItem] = React.useState();
  const [booth, setBooth] = React.useState();
  const [show, setShow] = React.useState();
  const [info, setInfo] = React.useState();

  React.useEffect(() => {
    async function f() {
      const booth = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShowBooth, {
          id: boothID,
        })
      );

      const boothItem = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShowBoothItem, { id })
      );

      const show = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShow, {
          id: showID,
        })
      );

      const userInfo = await API.graphql(
        // @ts-ignore
        graphqlOperation(listBoothUserInfos, {
          filter: {
            boothID: { eq: boothID },
          },
        })
      );

      // @ts-ignore
      setBooth(booth.data.getShowBooth);

      // @ts-ignore
      setBoothItem(boothItem.data.getShowBoothItem);

      // @ts-ignore
      setShow(show.data.getShow);

      // @ts-ignore
      setInfo(userInfo.data.listBoothUserInfos.items[0]);
    }
    f();
  }, [boothID, id, showID]);

  return (
    <Preview>
      {boothItem && info && show && booth && (
        <BoothItem
          boothItem={boothItem}
          info={info}
          show={show}
          booth={booth}
        />
      )}
    </Preview>
  );
}
