import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
type ShowProps = {
  showID?: String;
} & Router.RouteComponentProps;

export default function Shows(props: ShowProps) {
  const { showID, boothID } = props;
  const variables = {
    filter: {
      boothID: { eq: boothID },
    },
  };
  return (
    <>
      <Table type="showBoothItems" variables={variables} />
    </>
  );
}
