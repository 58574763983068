import * as React from "react";
import * as Router from "@reach/router";
import Preview from "./Preivew";
import Teaser from "components/pages/front/Teaser";

export default function Show() {
  const { showID } = Router.useParams();

  return (
    <Preview>
      <Teaser show={{ showID }} />
    </Preview>
  );
}
