import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
// export interface ShowProps extends Router.RouteComponentProps {}

type ShowProps = {
  showID?: String;
} & Router.RouteComponentProps;

export default function Shows(props: ShowProps) {
  // @todo 編集画面を通すとnot asignで通過してしまう
  // console.log(Router.useParams(),props);
  const { showID } = props;
  const variables = {
    filter: {
      showID: { eq: showID },
    },
  };
  return (
    <>
      <Table type="showSettings" dialog variables={variables} createLimit={1} />
    </>
  );
}
