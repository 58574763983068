import * as React from "react";
import Booth from "components/pages/front/Booth";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { getShow, getShowBooth, listBoothUserInfos } from "graphql/queries";
import Preview from "./Preivew";

export default function Show() {
  const { showID, id } = Router.useParams();
  const [booth, setBooth] = React.useState();
  const [show, setShow] = React.useState();
  const [info, setInfo] = React.useState();

  React.useEffect(() => {
    async function f() {
      const booth = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShowBooth, {
          id,
        })
      );

      const show = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShow, {
          id: showID,
        })
      );

      const userInfo = await API.graphql(
        // @ts-ignore
        graphqlOperation(listBoothUserInfos, {
          filter: {
            boothID: { eq: id },
          },
        })
      );

      // @ts-ignore
      setBooth(booth.data.getShowBooth);

      // @ts-ignore
      setShow(show.data.getShow);

      // @ts-ignore
      setInfo(userInfo.data.listBoothUserInfos.items[0]);
    }
    f();
  }, [id, showID]);

  return (
    <Preview>
      {info && show && booth && <Booth info={info} show={show} booth={booth} />}
    </Preview>
  );
}
