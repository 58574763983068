import i18n from "i18next";
import jaTranslation from "locales/ja/translation.json";
import { initReactI18next } from "react-i18next";

const resources = {
  ja: {
    translation: jaTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: "ja",
  fallbackLng: "ja",
  debug: true,

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
  },
  resources: resources,
});

export default i18n;
