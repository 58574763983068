import * as React from "react";
import Form from "components/organisims/Form";
import { image } from "settings/FormSettings";
import { onDeleteImage } from "graphql/subscriptions";
import { deleteAlbumPhot } from "graphql/mutations";
import { listAlbumPhots } from "graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

export default function Show() {
  // 画像を消した場合はアルバムなどの紐付けを消す
  const subscription = API.graphql(graphqlOperation(onDeleteImage)).subscribe({
    next: (target: any) => {
      async function f() {
        // @todo deleteの複数削除をしたい
        const res = await API.graphql(
          graphqlOperation(listAlbumPhots, {
            filter: { photID: { eq: target.value.data.onDeleteImage.id } },
          })
        );
        const res_photos = res.data.listAlbumPhots.items.map(async (item) => {
          await API.graphql(
            // @ts-ignore
            graphqlOperation(deleteAlbumPhot, {
              input: {
                id: item.id,
                expectedVersion: item.version,
              },
            })
          );
        });
      }
      f();
      subscription.unsubscribe();
    },
  });
  return <Form dataType="image" formParams={image} />;
}
