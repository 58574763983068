import * as React from "react";
import * as Router from "@reach/router";
import PreviewMain from "components/pages/cms/shows/preview/Main";

import Form from "components/organisims/Form";
import { showMain } from "settings/FormSettings";

export default function Show() {
  const { action, ...rest } = Router.useParams();

  return (
    <>
      {action !== "preview" && (
        <Form dataType="showMain" formParams={showMain} />
      )}

      {action === "preview" && <PreviewMain {...rest} />}
    </>
  );
}
