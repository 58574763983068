import * as React from "react";
import Form from "components/organisims/Form";
import * as Router from "@reach/router";
import { showBoothItem } from "settings/FormSettings";
import PreviewBoothItem from "components/pages/cms/shows/preview/BoothItem";

export default function Show() {
  const { action, ...rest } = Router.useParams();
  return (
    <>
      {action !== "preview" && (
        <Form
          dataType="ShowBoothItem"
          formParams={showBoothItem}
          hiddenCreateInputs={{ boothID: rest.boothID }}
        />
      )}

      {action === "preview" && <PreviewBoothItem {...rest} />}
    </>
  );
}
