import * as React from "react";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { getShow } from "graphql/queries";
import Preview from "./Preivew";
import Main from "components/pages/front/Main";

export default function Show() {
  const { showID } = Router.useParams();

  return (
    <Preview>
      <Main show={{ showID }} />
    </Preview>
  );
}
