import React from "react";
import * as Router from "@reach/router";
import Table from "components/molecures/Table";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Shows(props: ShowProps) {
  const { showID } = Router.useParams();
  const variables = {
    filter: {
      showID: { eq: showID },
    },
  };
  return (
    <>
      <Table type="showAudiences" variables={variables} />
    </>
  );
}
