import * as React from "react";
import * as Router from "@reach/router";
import Form from "components/organisims/Form";
import { showBooth } from "settings/FormSettings";
import PreviewBooth from "components/pages/cms/shows/preview/Booth";

export default function Show() {
  const { action, ...rest } = Router.useParams();
  return (
    <>
      {action !== "preview" && (
        <Form dataType="showBooth" formParams={showBooth} />
      )}

      {action === "preview" && <PreviewBooth {...rest} />}
    </>
  );
}
