import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";

export interface ShowProps extends Router.RouteComponentProps {}

export default function Shows(props: ShowProps) {
  const variables = {
    limit: 2000,
  };
  return (
    <>
      <Table type="images" variables={variables} />
    </>
  );
}
