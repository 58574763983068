import * as React from "react";
import Form from "components/organisims/Form";
import * as Router from "@reach/router";
import { boothUserInfo } from "settings/FormSettings";

export default function Show() {
  const { boothID } = Router.useParams();
  return (
    <Form
      dataType="boothUserInfo"
      formParams={boothUserInfo}
      hiddenCreateInputs={{ boothID: boothID }}
    />
  );
}
